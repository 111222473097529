<template>
    <div>
        <van-nav-bar title="设备详情" left-text="返回" @click-left="$router.go(-1)" left-arrow>
             <!-- <template #right>
                <van-icon name="apps-o" size="18" @click="dialog.dev = true"/>
            </template> -->
        </van-nav-bar>
        <div class="title">
            <p>{{infoBase.devName || '暂无名称'}}</p>
            <!-- <p class="address">{{infoBase.location || '暂未设置地址'}}</p> -->
        </div>
        <div class="panel glo-relative">
            <img v-if="!active.chartShow" src="@/assets/icps/img/dev_structure.png">
            <div v-if="!active.chartShow" class="bat-position">
                <div class="flex-top-info">
                    <div class="margin-right-0">
                        <i :class="'iconfont '+(infoBase.devStatus === 'online' && infoBase.chargeStatus === 'charging' ? 'color1' : 'color2')">&#xe64b;</i>
                    </div>
                    <div>
                        <img v-if="Math.abs(info.rssi) < 1 || !info.rssi" class="signal" src="@/assets/icps/img/signal-0.png" />
                        <img v-if="Math.abs(info.rssi) > 100" class="signal" src="@/assets/icps/img/signal-1.png" />
                        <img v-if="Math.abs(info.rssi) <= 100 && Math.abs(info.rssi) >90" class="signal" src="@/assets/icps/img/signal-2.png" />
                        <img v-if="Math.abs(info.rssi) <= 90 && Math.abs(info.rssi) >80" class="signal" src="@/assets/icps/img/signal-3.png" />
                        <img v-if="Math.abs(info.rssi) <= 80 && Math.abs(info.rssi) >5" class="signal" src="@/assets/icps/img/signal-4.png" />
                    </div>
                </div>
            </div>
            <canvas v-else id="batteryCurve"></canvas>
        </div>
        <van-button class="icon-btn" size="small" color="#019af3" icon="notes-o" type="primary" @click="drawChart">
            {{active.chartShow ? '关闭24小时电量变化曲线' : '显示24小时电量变化曲线'}}
        </van-button>
        <div class="info">
            <van-row>
                <van-col span="12" :class="info.batLevel <= 20 ?'red':''">电池电量：<span>{{info.batLevel || 100}}% </span></van-col>
            </van-row>
            <van-row>
                <van-col span="12" :class="infoBase.devStatus === 'online' && infoBase.chargeStatus === 'charging' ?'green':''">充电状态：<span>{{infoBase.devStatus === 'online' && infoBase.chargeStatus === "charging" ? '正在充电' : '未充电'}}</span></van-col>
                <van-col span="12" :class="info.output === '1' ?'color1':''">
                    <div class="align-center">
                        <div>放电开关：</div>
                        <div>
                            <van-switch v-model="system.status" size="15px" active-color="#019af3" :active-value='1' :inactive-value='0' @change="changeSwitch"/>
                        </div>
                    </div>
                </van-col>
            </van-row>
            <van-row>
                <van-col span="12" :class="info.workTemp > 60 ?'red':''">驱动核心温度：<span>{{info.workTemp == null ? '0.00' : Number(info.workTemp).toFixed(2)}}℃</span></van-col>
                <van-col span="12">电池温度：<span>{{info.batT1 == null ? '0.00' : Number(info.batT1).toFixed(2)}} ℃</span></van-col>
            </van-row>
            <van-row>
                <van-col span="12">电芯电压：<span>{{info.batVol == null ? '0.00' : Number(info.batVol).toFixed(2)}} V</span></van-col>
                <!-- <van-col span="12">
                    {{infoBase.devStatus === 'charging' ? '充电电流：' : '放电电流：'}}
                    <span>{{info.batCur == null ? '0.00' : Number(info.batCur).toFixed(2)}} A</span>
                </van-col> -->
                <van-col span="12">
                    <div v-if="infoBase.devStatus !== 'offline'">
                        {{infoBase.chargeStatus === 'charging' ? '充电功率：' : '放电功率：'}}
                        <span>{{ info.batVol && info.batCur ? (Number(info.batVol) * Math.abs(info.batCur)).toFixed(2) : '0.00' }} W</span>
                    </div>
                    <div v-else>
                        输出功率：<span>0.00 W</span>
                    </div>
                </van-col>
            </van-row>
            <van-row>
                <van-col span="12">硬件版本：<span>{{info.hwVer || 'v1.0'}}</span></van-col>
                <van-col span="12">软件版本：<span>{{info.swVer || 'v1.0'}}</span></van-col>
            </van-row>
            <van-row>
                <van-col span="12">
                    倾角：
                    <span v-if="Number(info.accAngle) >=0 && Number(info.accAngle) <= 20">{{info.accAngle}} ° （直立）</span>
                    <span v-else-if="Number(info.accAngle) >=70 && Number(info.accAngle) <= 110">{{info.accAngle}} ° （平放）</span>
                    <span v-else-if="Number(info.accAngle) >=160 && Number(info.accAngle) <= 180">{{info.accAngle}} ° （直立）</span>
                    <span v-else>{{info.accAngle || 0}} ° </span>
                </van-col>
            </van-row>
            <!-- <van-row>
                <van-col span="12">定位标志：<span>{{info.gpsFlag || 0}}</span></van-col>
            </van-row> -->
            <div>经纬度：<span>（{{infoBase.lon || '0.000'}} 'E，{{ infoBase.lat || '0.000'}} 'N） <i class="iconfont">&#xe618;</i> {{info.gpsNum || 0}}</span></div>
            <div>更新时间：<span>{{info.time || '0000-00-00 00:00:00'}}</span></div>
        </div>
        <!-- 弹出组件汇总 -->
        <van-dialog v-model="dialog.dev" title="设备系统操作" :show-confirm-button="false" close-on-click-overlay>
            <div class="margin-block">
                <van-row>
                    <van-col span="12" class="title-switch">
                        智能充电包开关
                    </van-col>
                    <van-col span="12">
                        <van-switch v-model="system.status" active-color="#019af3" :active-value='1' :inactive-value='0' @change="changeSwitch"/>
                    </van-col>
                </van-row>
            </div>
        </van-dialog>
    </div>
</template>
<script>
import F2 from '@antv/f2';
export default {
    data(){
        return{
            active:{
                chartData:[],
                chartShow:false,
            },
            info:{
                batCur:0
            },
            infoBase:{},
            system:{
                show:false,
                status:0,
            },
            dialog:{
                dev:false
            },
            timer:null
        }
    },
    methods:{
        // 系统管理开关
        changeSwitch(val){
            let data ={
                devCode:this.$route.query.devCode,
                status:val //0 断电 1 供电
            }
            this.$api.ICPS.controlSystem('charge',data).then( d => {
                this.$toast('操作成功');
            })
        },
        // 获取设备曲线
        getBattery(){
            this.$api.ICPS.devBattery(this.$route.query.devCode).then((d)=>{
                if(Array.isArray(d)){
                    this.active.chartData = d.map ( v => ({date:v.recordTime,value:Number(v.batLevel)})); 
                }
            })
        },
        // 绘制电量曲线
        drawChart(){
            this.active.chartShow = !this.active.chartShow;
            if(this.active.chartShow){
                this.$nextTick(()=>{
                    const chart = new F2.Chart({
                        id: 'batteryCurve',
                        pixelRatio: window.devicePixelRatio
                    });

                    const defs = {
                        date: {
                            type: 'timeCat',
                            mask: 'HH:mm',
                            tickCount: 7,
                            range: [ 0, 1 ]
                        },
                        value: {
                            tickCount: 5,
                            min: 0,
                            alias: '电量'
                        }
                    };
                    // 坐标系X轴 文本对齐
                    chart.axis('date', {
                        label: function label(text, index, total) {
                            const textCfg = {};
                            if (index === 0) {
                            textCfg.textAlign = 'left';
                            }
                            if (index === total - 1) {
                            textCfg.textAlign = 'right';
                            }
                            return textCfg;
                        }
                    });
                    chart.source(this.active.chartData, defs);
                    // 添加图例
                    chart.legend({
                        custom: true,
                        position: 'top',
                        align:'right',
                        items: [
                            { name: '电量曲线', marker: 'square', fill: '#096DD9'},
                        ]
                    });
                    chart.tooltip({
                        showCrosshairs: true,
                        // 提示条点击显示具体时间
                        onShow: function onShow(ev) {
                            const items = ev.items;
                            items[0].name = items[0].origin.date;
                        }
                    });
                    chart.area().position('date*value');
                    chart.line().position('date*value').shape('smooth');
                    chart.render(); 
                })
            } 
        },
        // 获取设备数据
        getPageInfo(){
            return new Promise( resolve => {
                this.$api.ICPS.devNewInfo(this.$route.query.devCode).then( d => {
                    this.info = {...d}
                    this.system.status = Number(d.output) // 回显当前充电状态
                    resolve()
                }) 
            })
        },
        // 获取设备基础信息
        getDevInfo(){
            return new Promise( () => {
                this.$api.ICPS.devSelfInfo(this.$route.query.devCode).then( d => {
                    this.infoBase={...d}
                }) 
            })
        }
    },
    mounted(){
        this.getDevInfo()
        this.getPageInfo().then(() =>{
            this.getBattery();
        })
        // 定时任务-轮询
        this.timer = setInterval(()=>{
            this.getDevInfo()
            this.getPageInfo()
        },30*1000)
    },
    beforeDestroy(){
        clearInterval(this.timer)
    },
    beforeRouteEnter(to, from, next) {
        document.querySelector('body').setAttribute('style', 'background-color:#ffffff');
        next();
    },
    beforeRouteLeave(to, from, next) {
        document.querySelector('body').setAttribute('style', 'background-color:#f0f0f0');
        next();
    }
}
</script>

<style lang="scss" scoped>
.title{
    color: #019af3;
    font-weight: bold;
    font-size: 1rem;
    p{margin: 8px;}
    .address{
        font-size: .85rem;
        color: #a9a9a9;
    }
}
.panel{
    padding: 2%;
    position: relative;
    img{
        width: calc(100% - 4px);
        height: 100%;
        border: 2px solid #e8e8e8;
        box-shadow: 2px 2px 1px #dbe3ea;
    }
    #batteryCurve{width: 100%;height: 17rem;}
}
.icon-btn{
    width: calc(100% - 16px);
    button{display: block;margin-bottom: 10px;}
}
.info{
    &>div{margin-bottom: 2%;}
    .red{color:red}
    .green{
        color: #00bd00;
    }
    padding: 14px 12px;
    font-size: .85rem;
    text-align: left;
    span{
        font-weight: bold;
    }
}
.margin-block{
    margin: 4%;
    font-size: .85rem;
    .van-cell{padding: 6px 16px;}
    .fa{font-size: 1.5rem;}
    .fa-spinner{font-size: 1.6rem;margin-bottom: 10%;}
    
}
.van-radio-group--horizontal{margin: 5% 2%;}
.title-switch{
    font-weight: bold;
    line-height: 2;
}
.bat-position{
    position: absolute;
    top: 8%;
    right: 5%;
    i{
        font-size: 1.5rem;
    }
}
.color1{
    color: rgb(1, 154, 243);
}
.color2{
    color: #9a9a9a;
}
.color3{
    color: red;
}
.align-center{
    display: flex;
    align-items: center;
}
.flex-top-info{
    display: flex;
    .signal{
        border: none;
        width: 24px;
        height: 24px;
        box-shadow: none;
    }
    .margin-right-0{
        margin-right: 6px;
    }
}
</style>